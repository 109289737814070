import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      alias: '/index.html'
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import('./views/FAQ.vue')
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('./views/Privacy.vue')
    },
    {
      path: '/app',
      name: 'app',
      component: () => import('./views/ComeSto.vue')
    },
    {
      path: '/app/proceduraGuidata',
      name: 'proceduraGuidata',
      component: () => import('./views/ProceduraGuidata.vue')
    },
    {
      path: '/app/protected',
      name: 'protected',
      component: () => import('./views/AskPassword.vue')
    }
  ]
})
