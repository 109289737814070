import Vue from 'vue'
import Buefy from 'buefy'
import '@/styles/main.scss'
import '@mdi/font/scss/materialdesignicons.scss'
import App from './App.vue'
import router from './router'
import store from './store'
import SocialSharing from 'vue-social-sharing'
import Clipboard from 'v-clipboard'
import Ads from 'vue-google-adsense'
import VueGAPI from "vue-gapi";
 
import './registerServiceWorker'

Vue.config.productionTip = false

Vue.use(Buefy, {
  defaultDatepickerMobileNative: false,
  defaultDayNames: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
  defaultMonthNames: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
  defaultFirstDayOfWeek: 1
})
Vue.use(SocialSharing)
Vue.use(Clipboard)

Vue.use(require('vue-script2'))

Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)

const googleConfig = {
  apiKey: 'AIzaSyBAiKcRXdpMNsdPgB58Tq4GA4yR0W9u-Ss',
  clientId: '379046058249-1ggjdtucvqngfaag1jjjsvh50341gr04.apps.googleusercontent.com',
  scope: 'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.appdata',
  discoveryDocs: []
}
Vue.use(VueGAPI, googleConfig);

let vue = new Vue({
  router,
  store,
  render: h => h(App)
})

vue.$mount('#app')
