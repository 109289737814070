<template>
  <div class="landing">
    <!-- <section class="hero is-white" style="width: 100%">
  <div class="hero-body">
    <div class="container">
      <h1 class="title">
        Primary title
      </h1>
      <h2 class="subtitle">
        Primary subtitle
      </h2>
    </div>
  </div>
    </section>-->
    <section class="hero is-fullheight is-default is-bold">
      <div class="hero-head"></div>
      <div class="hero-body">
        <div class="container">
          <div class="columns is-vcentered">
            <div class="column is-5 is-offset-1 landing-caption">
              <h1 class="title is-1 is-bold is-spaced">Osserva, Risparmia.</h1>
              <h2 class="subtitle is-5 is-muted">
                Carica i dati dalla tua banca tramite export Excel.
                <br />Comprendi la tua situazione economica. Effettua l'analisi delle spese e visualizza l'andamento dei tuoi conti.
                <br />L'applicazione è
                <b>gratuita</b>.
              </h2>
              <p>
                <router-link to="/app">
                  <a class="button cta rounded primary-btn raised" title="Vai all'applicazione">Inizia subito</a>
                </router-link>
              </p>
            </div>
            <div class="column is-5 is-offset-1">
              <figure class="image is-4by3">
                <img src="../assets/brand.svg" alt="Il servizio della tua banca non offre grafici e categorizzazione dei movimenti? ComeSto.biz è quello di cui hai bisogno" />
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div class="hero-foot">
           <!-- <Adsense
            data-ad-client="ca-pub-8018925037718321"
            data-ad-slot="6891980674"
            style="text-align: center"
            ins-style="width:728px;height:50px"
          ></Adsense> -->
        <!-- <div class="container">
          <div class="tabs is-centered">
            <ul>
              <li>
                <a>
                  <img class="partner-logo" src="assets/images/logos/clients/systek.svg" />
                </a>
              </li>
              <li>
                <a>
                  <img class="partner-logo" src="assets/images/logos/clients/tribe.svg" />
                </a>
              </li>
              <li>
                <a>
                  <img class="partner-logo" src="assets/images/logos/clients/kromo.svg" />
                </a>
              </li>
              <li>
                <a>
                  <img class="partner-logo" src="assets/images/logos/clients/infinite.svg" />
                </a>
              </li>
              <li>
                <a>
                  <img class="partner-logo" src="assets/images/logos/clients/gutwork.svg" />
                </a>
              </li>
            </ul>
          </div>
        </div>-->
      </div>
    </section>

    <section class="section section-feature-grey is-medium">
      <div class="container">
        <div class="title-wrapper has-text-centered">
          <h2 class="title is-2">Pochi semplici passi</h2>
          <h3 class="subtitle is-5 is-muted">...e la tua situazione economica non avrà più segreti!</h3>
          <div class="divider is-centered"></div>
        </div>

        <div class="content-wrapper">
          <div class="columns">
            <div class="column">
              <div
                class="feature-card is-bordered has-text-centered revealOnScroll delay-1"
                data-animation="fadeInLeft"
              >
                <div class="card-title">
                  <h4>
                    Carica movimenti
                    <br />anche da più conti diversi
                  </h4>
                </div>
                <div class="card-icon">
                  <img src="../assets/bank.svg" alt="Banca" />
                </div>
                <div class="card-text">
                  <p>Esporta Excel direttamente dal tuo online banking</p>
                </div>
              </div>
            </div>
            <div class="column">
              <div
                class="feature-card is-bordered has-text-centered revealOnScroll delay-2"
                data-animation="fadeInLeft"
              >
                <div class="card-title">
                  <h4>
                    Organizza e categorizza
                    <br />i tuoi movimenti
                  </h4>
                </div>
                <div class="card-icon">
                  <img src="../assets/organize.svg" alt="Organizza i tuoi movimenti" />
                </div>
                <div class="card-text">
                  <p>Possibilità di categorizzazione automatica e massiva</p>
                </div>
              </div>
            </div>
            <div class="column">
              <div
                class="feature-card is-bordered has-text-centered revealOnScroll delay-2"
                data-animation="fadeInLeft"
              >
                <div class="card-title">
                  <h4>
                    Comprendi i tuoi flussi
                    <br />di spesa e guadagno
                  </h4>
                </div>
                <div class="card-icon">
                  <img src="../assets/report.svg" alt="Visualizza il report" />
                </div>
                <div class="card-text">
                  <p>Beneficia degli strumenti per rispondere.. "comesto"?</p>
                </div>
              </div>
            </div>
            <div class="column">
              <div
                class="feature-card is-bordered has-text-centered revealOnScroll delay-3"
                data-animation="fadeInLeft"
              >
                <div class="card-title">
                  <h4>Risparmia e pianifica le tue spese consapevolmente</h4>
                </div>
                <div class="card-icon">
                  <img src="../assets/consapevole.svg" alt="Passa all'azione!" />
                </div>
                <div class="card-text">
                  <p>Prendi le azioni necessarie e continua a utilizzare "comesto" periodicamente!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section is-medium">
      <div class="container">
        <div class="columns">
          <div class="column is-centered-tablet-portrait">
            <h1 class="title section-title">Teniamo alla tua privacy</h1>
            <h3 class="subtitle is-4 is-muted">caratteristiche principali</h3>
            <div class="divider"></div>

            <Adsense
              class="message"
              data-ad-client="ca-pub-8018925037718321"
              data-ad-slot="1050774286"
              data-ad-format="auto"
              :data-full-width-responsive="true"
              style="text-align: center; margin-top: 20px"
            ></Adsense>
          </div>
          <div class="column is-8 mt-60">
            <!-- icon block -->
            <article class="media icon-box">
              <figure class="media-left">
                <p class="image">
                  <img src="../assets/local_distribution.svg" style="width: 80px" alt="Distribuzione locale" />
                </p>
              </figure>
              <div class="media-content">
                <div class="content">
                  <p>
                    <span class="icon-box-title">Online delivery, Local execution</span>
                    <span
                      class="icon-box-text"
                    >Applicazione distribuita via internet ma eseguita esclusivamente sul tuo computer. Niente esce dal tuo browser</span>
                  </p>
                </div>
              </div>
            </article>
            <!-- /icon block -->

            <!-- icon block -->
            <article class="media icon-box">
              <figure class="media-left">
                <p class="image">
                  <img src="../assets/encryption_lock.svg" style="width: 80px; height: 70px" alt="sicuro" />
                </p>
              </figure>
              <div class="media-content">
                <div class="content">
                  <p>
                    <span class="icon-box-title">Data Encryption</span>
                    <span
                      class="icon-box-text"
                    >I dati sono criptati SHA256 in modo tale che solo tu puoi visualizzarli, nel massimo rispetto della tua privacy</span>
                  </p>
                </div>
              </div>
            </article>
            <!-- /icon block -->

            <!-- icon block -->
            <article class="media icon-box">
              <figure class="media-left">
                <p class="image">
                  <img src="../assets/automatic_recognition.svg" style="width: 80px; height: 70px" alt="Automatic Excel Recognition" />
                </p>
              </figure>
              <div class="media-content">
                <div class="content">
                  <p>
                    <span class="icon-box-title">Automatic Excel Recognition</span>
                    <span
                      class="icon-box-text"
                    >Riconoscimento automatico degli excel prodotti dal tuo online banking</span>
                  </p>
                </div>
              </div>
            </article>
            <!-- /icon block -->

            <!-- icon block -->
            <article class="media icon-box">
              <figure class="media-left">
                <p class="image" style="margin-right: 20px">
                  <img
                    src="../assets/gratis.svg"
                    style="height: 80px; width:60px; fill-opacity: 1;"
                    alt="Gratis. Free."
                  />
                </p>
              </figure>
              <div class="media-content">
                <div class="content">
                  <p>
                    <span class="icon-box-title">Gratis</span>
                    <span
                      class="icon-box-text"
                    >L'applicazione è gratuita. Ci sosteniamo tramite inserti pubblicitari e sopratutto con una tua donazione</span>
                  </p>
                </div>
              </div>
            </article>
            <!-- /icon block -->
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="section section-feature-grey is-medium">
      <div class="container">
        <div class="columns">
          <div class="column is-10 is-offset-1">
            <div class="has-text-centered">
              <img class="pushed-image" src="assets/images/illustrations/mockups/app-mockup.png" />
            </div>
          </div>
        </div>

        <div class="title-wrapper has-text-centered">
          <h2 class="title is-2">One Platform</h2>
          <h3 class="subtitle is-5 is-muted">To rule them All</h3>
        </div>

        <p class="has-text-centered mt-20">
          <a class="button cta is-large rounded secondary-btn raised">Get Started</a>
        </p>
      </div>
    </section>-->

    <section class="section is-medium section-secondary">
      <div class="container">
        <div class="title-wrapper has-text-centered">
          <h2 class="title is-2 light-text is-spaced">Le storie dei nostri utenti</h2>
          <h3 class="subtitle is-5 light-text">
            Inviaci le tue esperienze ad
            <a
              class="mail"
              href="mailto:assistenza@comesto.biz"
              title="Contattaci"
            >assistenza@comesto.biz</a>
          </h3>
        </div>

        <div class="content-wrapper">
          <div class="columns is-vcentered">
            <div class="column is-4">
              <figure class="testimonial">
                <blockquote>
                  Cerco sempre di tenere sotto controllo le spese della casa.
                  <br />Tracciavo tutto con carta e penna ma ormai tutto passa dalla banca..
                  <br />con ‘Come stò’ riesco finalmente a tener sotto controllo il budget mensile in modo automatico grazie all'autoriconoscimento dell'excel della mia banca. E niente, sono soddisfatta!
                </blockquote>
                <div class="author">
                  <img src="../assets/faces/1.png" alt="Paola" />
                  <h5>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Paola</h5>
                  <span>Esperta di logistica</span>
                </div>
              </figure>
            </div>
            <div class="column is-4">
              <figure class="testimonial">
                <blockquote>
                  Sin da ragazzo volevo visitare l'India... i suoi colori, la storia e il cibo... ne sono sempre rimasto affascinato.
                  <br />Da qualche anno, grazie al mio nuovo lavoro, penso di potermelo permettere.
                  <br />Trovo questa applicazione molto utile per pianificare le mie entrate e uscite da qui sino alla mia partenza.
                  <br />Namaste!
                </blockquote>
                <div class="author">
                  <img src="../assets/faces/2.png" alt="Luca" />
                  <h5>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Luca</h5>
                  <span>Analista finanziario</span>
                </div>
              </figure>
            </div>
            <div class="column is-4">
              <figure class="testimonial">
                <blockquote>
                  Da quando è nata mia figlia tutto è cambiato.
                  Ho comprato casa accendendo un mutuo a tasso variabile ma ho avuto momenti di ansia perchè tra la rata mensile, la postpay e il conto, non sapevo più ‘come stò’.
                  <br />Grazie a voi riesco finalmente a riconciliare i vari movimenti e vedere chiaramente la mia situazione.
                </blockquote>
                <div class="author">
                  <img src="../assets/faces/3.png" alt="Giorgio" />
                  <h5>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Giorgio</h5>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Impiegato</span>
                </div>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Adsense
      data-ad-client="ca-pub-8018925037718321"
      data-ad-slot="3144307357"
      data-ad-format="auto"
      :data-full-width-responsive="true"
      style="text-align: center"
    ></Adsense>

    <section class="section section-feature-grey is-medium">
      <div class="container">
        <div class="title-wrapper has-text-centered">
          <h2 class="title is-2">5 abitudini</h2>
          <h3 class="subtitle is-5 is-muted">che ti aiuteranno a risparmiare</h3>
          <div class="divider is-centered"></div>
        </div>

        <div class="content-wrapper">
          <h3 class="subtitle is-5 is-muted">
            Spendere è più facile che risparmiare.
            <br />E diventa ancor più una sfida se lo stipendio è amalapena sufficiente per raggiungere il prossimo bonifico.
            <br />Ma anche con dei guadagni limitati e possibile mettere dei soldi a parte. Scopri 5 abitudini che ti aiuteranno a raggiungere l’obiettivo.
          </h3>

          <div class="tile is-ancestor">
            <div class="tile is-vertical is-12">
              <div class="tile">
                <div class="tile is-parent is-vertical">
                  <article class="tile is-child notification is-grey">
                    <p class="title">Tracciare le spese</p>
                    <p class="subtitle">L'abc</p>
                    <p class="content">
                      Anche se ti può sembrare che ricordi bene come hai speso i soldi, puoi essere sorpreso quanto budget se ne è andato in piccole cose.
                      <br />Puoi usare carta e penna per tracciare cosa hai pagato. Oppure, se sei abituato a pagare con la carta, puoi usare la nostra applicazione ‘Come stò’.
                    </p>
                  </article>
                  <article class="tile is-child notification is-warning">
                    <p class="title">Raziona le tue entrate</p>
                    <p class="subtitle">Essere come una formichina</p>
                    <p class="content">
                      Appena hai ricevuto il bonifico metti una percentuale fissa nel tuo conto deposito (per esempio 10%).
                      Questa tattica ti aiuterà a capire quale budget hai realmente disponibile e ti inclinerà a pianificare al meglio il mese.
                      Infatti, ci sarà meno probabilità di usare i soldi messi nel conto deposito in quanto ti sentirai limitato.
                      <br />Inoltre, puoi strutturare i tuoi risparmi con altri strumenti finanziari.
                      Una parte potrebbero essere dei risparmi a lungo termine in obbligazioni o altri strumenti simili. Mentre, un'altra parte, potrebbero essere dei risparmi più facilmente accessibili in caso di necessità.
                    </p>
                  </article>
                </div>
                <div class="tile is-parent is-vertical">
                  <article class="tile is-child notification is-info">
                    <p class="title">Spendere meno anzichè guadagnare.</p>
                    <p class="subtitle">La dura via</p>
                    <p class="content">
                      Sembra ovvio, ma non lo è.
                      <br />Non essere tentato da prestiti e finanziamenti vagamente vantaggiosi. Puntualmente, una volta che hai soddisfatto le tue necessità (per esempio andare in vacanza), non ti sentirai motivato per coprire le rate. Che fregatura!
                    </p>
                  </article>
                  <article class="tile is-child notification is-danger">
                    <p class="title">Desisti dall'acquisto impulsivo</p>
                    <p class="subtitle">Sangue freddo</p>
                    <div class="content">
                      Sicuramente ti sarà capitato di essere attratto dalle vetrine di qualche negozio con grandi insegne "sconti fino a 70%", prima di fiondarti dentro e acquistare tutto meglio aspettare 1-2 giorni.
                      Ritornaci successivamente e se la cosa non c’è più, sappi che hai risparmiato 100%.
                    </div>
                  </article>
                </div>
              </div>

              <div class="tile is-parent">
                <article
                  class="tile is-child notification"
                  style="background-color: #1d964c; color: white"
                >
                  <div class="content">
                    <p class="title">Se hai ricevuto dei soldi extra, non spenderli tutti subito</p>
                    <p class="subtitle">Vivere il momento con un pizzico di saggezza</p>
                    <div class="content">
                      Se hai ricevuto un aumento, non cambiare lo stile di vita subito. Questa è una buona occasione per avere più risparmi. Ti aiuterà il fatto che hai già vissuto con quel budget finora. Significa che è possibile.
                      Se hai ricevuto i soldi extra, mettili nel deposito.
                      <br />Oppure, quando hai finito di pagare un prestito, prendi l’abitudine di trasferire lo stesso importo al conto risparmio.
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="hero is-fullheight is-default is-bold">
      <div class="hero-head"></div>
      <div class="hero-body">
        <div class="container">
          <div class="columns is-vcentered">
            <div class="column is-5 is-offset-1 landing-caption">
              <h1 class="title is-1 is-bold is-spaced">Sostienici</h1>
              <h2 class="subtitle is-5 is-muted">
                ‘Come stò’ è un'applicazione rilasciata gratuitamente perchè riteniamo che tutti debbano essere consapevoli della loro situazione economica.
                <br />Se puoi permettertelo, aiutaci con un contributo.
                <br />Grazie.
              </h2>
              <p>
                <a
                  class="button cta rounded primary-btn raised"
                  @click="isDonazioniModalOpen=true"
                  title="Clicca qui per donare"
                >Donazione</a>
              </p>
            </div>
            <div class="column is-5 is-offset-1">
              <figure class="image is-4by3">
                <img src="../assets/donazione_office.svg" alt="Supportaci con una donazione, grazie" />
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div class="hero-foot">
        <!-- <div class="container">
          <div class="tabs is-centered">
            <ul>
              <li>
                <a>
                  <img class="partner-logo" src="assets/images/logos/clients/systek.svg" />
                </a>
              </li>
              <li>
                <a>
                  <img class="partner-logo" src="assets/images/logos/clients/tribe.svg" />
                </a>
              </li>
              <li>
                <a>
                  <img class="partner-logo" src="assets/images/logos/clients/kromo.svg" />
                </a>
              </li>
              <li>
                <a>
                  <img class="partner-logo" src="assets/images/logos/clients/infinite.svg" />
                </a>
              </li>
              <li>
                <a>
                  <img class="partner-logo" src="assets/images/logos/clients/gutwork.svg" />
                </a>
              </li>
            </ul>
          </div>
        </div>-->
      </div>
    </section>

    <Donazione :modalOpen.sync="isDonazioniModalOpen" />

    <transition name="fade">
      <a v-show="showBtt" class="btt" role="button" @click="backToTop" title="Torna in cima">
        <b-icon icon="arrow-up-bold-circle" size="is-large" />
      </a>
    </transition>
  </div>
</template>

<script>
import Donazione from "@/components/Donazione.vue";

export default {
  name: "home",
  components: {
    Donazione
  },
  data: function() {
    return {
      showBtt: false,
      isDonazioniModalOpen: false
    };
  },
  methods: {
    backToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth"
      });
    }
  },
  beforeMount() {
    if (typeof window !== "undefined") {
      document.addEventListener("scroll", () => {
        this.showBtt = window.scrollY > 300;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.landing {
  @import "@/styles/landing/core.scss";

  width: 100%;
}
</style>

