import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'

// Import the `getField` getter and the `updateField`
// mutation function from the `vuex-map-fields` module.
import { getField, updateField } from 'vuex-map-fields';

import localforage from 'localforage';
import moment from "moment";
import CryptoJS from "crypto-js";

Vue.use(Vuex)

let defaultKey = 0xB8C3
let encrypt = function (key, value) {
  if (key != null) {
    return CryptoJS.AES.encrypt(JSON.stringify(value), key).toString()
  } else {
    let valueString = JSON.stringify(value);
    return [...Array(valueString.length).keys()].map(i => String.fromCharCode(valueString.charCodeAt(i) ^ defaultKey)).reduce((previous, current) => previous.concat(current), "");
  }
}
let decrypt = function (key, value) {
  if (value == null)
    return null;

  if (key != null) {
    var bytes = CryptoJS.AES.decrypt(value, key);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    let decryptedValue = [...Array(value.length).keys()].map(
      i => String.fromCharCode(value.charCodeAt(i) ^ defaultKey))
      .reduce((previous, current) => previous.concat(current), "");
    return JSON.parse(decryptedValue);
  }
}

let persistedStore = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: 'comesto',
  version: 1.0,
  storeName: 'vuex'
});

const categorieArr = [
  // Always
  { id: 2, name: 'Affitto o Mutuo', color: '#dc0b3c', words: ["affitto", "mutuo", "locazione", "IMMOBILIARE"] },
  { id: 4, name: 'Bollette', color: '#3E49BB', words: ["enel", "optima", "estra", "agsm", "iren", "sorgenia", "enelenergia", "illumia", "fastweb", "iliad", "tim", "vodafone", "wind"] },
  { id: 6, name: 'Cibo', color: '#009888', words: ["coop", "ipercoop", "unicoop", "incoop", "eurospin", "conad", "penny", "esselunga", "lidl", "CARR", "carrefour", "pam", "bennet", "spar", "fresco", "tuodi"] },

  // Often
  { id: 20, name: 'Trasporto privato', color: '#FFCD00', words: ["beyfin", "tamoil", "q8", "EASYPARK", "PEDAGGI AUTOSTRADALI", "Telepass", "OIL", "PARCHEGGIO", "PARCOMETRO"] },
  { id: 21, name: 'Trasporto pubblico', color: '#00A5F9', words: [] },

  // Sometimes
  { id: 5, name: 'Casa', color: '#FF5500', words: ["obi", "risparmio casa", "leroy merlin", "ikea", "casabalo", "mondo convenienza", "arcobalo", "EURONICS"] },
  { id: 17, name: 'Servizi', color: '#7F4FC9', words: [] },
  { id: 1, name: 'Abbigliamento e Accessori', color: '#9E9E9E', words: ["ALCOTT", "H & M", "h&m", "zara", "ovs", "TEZENIS", "desigual", "carpisa", "tally weijl", "sisley", "promod", "maxim"] },
  { id: 16, name: 'Salute e Bellezza', color: '#1abb63', words: ["farmacia", "douglas", "kiko", "MISERICORDIA", "DENTAL", "CASA DI CURA", "ASL", "Odontoiatrica"] },

  // Extra - Pleasure
  { id: 15, name: 'Ristoranti', color: '#D40C00', words: ["TAVERNA", "OLD WILD WEST", "MC DONALD", "Burger King", "GELATERIA", "BAR", "pasticceria", "RISTORANTE", "OSTERIA", "PIZZERIA"] },
  { id: 10, name: 'Divertimento', color: '#00BCD9', words: ["CINECLUB", "CINEMA", "Teatro", "MULTISALA"] },
  { id: 11, name: 'Hobby', color: '#FFEF00', words: ["decathlon"] },
  { id: 23, name: 'Vizi', color: '#CDE000', words: [] },

  // Extra - Needs
  { id: 22, name: 'Vacanze', color: '#fda5e6', words: ["BOOKING.COM", "VOLOTEA", "Ryanair", "Hotel"] },
  { id: 12, name: 'Istruzione', color: '#5F7D8E', words: [] },

  // Sacrificable
  { id: 3, name: 'Animali domestici', color: '#FF9A00', words: [] },
  { id: 13, name: 'Regali', color: '#F87979', words: [] },

  // Occasional
  { id: 19, name: 'Tasse e tributi', color: '#87C735', words: [] },
  { id: 9, name: 'Debiti', color: '#526EFF', words: [] },
  { id: 8, name: 'Crediti', color: '#d05633', words: [] },
  { id: 14, name: 'Risparmi', color: '#4f5fff', words: [] },
  { id: 18, name: 'Stipendio', color: '#626262', words: ["Stipendio", "Busta paga"] },
  { id: 7, name: 'Contanti', color: '#AA0505', words: ["Bancomat", "PagoBancomat", "ATM"] },
  { id: 24, name: 'Altre spese', color: '#eee9a5', words: [] }
]

let getAltreSpeseCategoriaIndex = function () {
  return categorieArr
    .map(cat => cat.name)
    .indexOf("Altre spese");
}

let initialState = {
  version: "1.0.0",
  categorie: categorieArr.sort((a, b) => a.id - b.id), // ordinati per id per gestire le categorie custom in futuro, "per coincidenza" sono ordinati lessicograficamente
  dati: [],
  saldo: 0,
  contanti: 0,
  debiti: 0,
  crediti: 0,
  bonus: 0,
  monthDayStart: 1,
  cookieAccepted: false,
  encrypted: false,
  encryptionKey: null,
  askPasswordIdOfSetTimeout: null,
  synchInCloud: false,
  cloudFileId: null,
  cloudState: ""
}
let persistedFields = ['version', 'dati', 'saldo', 'contanti', 'debiti', 'crediti', 'bonus', 'monthDayStart', 'cookieAccepted', 'encrypted']
let encryptedFields = ['version', 'dati', 'saldo', 'contanti', 'debiti', 'crediti', 'bonus', 'monthDayStart']

let lightLoadedFields = ['cookieAccepted', 'encrypted']

let computeEncryptedPersistableState = function (state) {
  let computedState = {}
  persistedFields.forEach(field => {
    if (encryptedFields.includes(field)) {
      computedState[field] = encrypt(state.encryptionKey, state[field])
    } else {
      computedState[field] = state[field]
    }
  })
  return computedState
}

export default new Vuex.Store({
  state: {
    ...initialState,
    isStateFullyLoaded: false,
    tabView: 'tabella'
  },
  getters: {
    datiEntrate(state) {
      return state.dati.filter(d => d.importo > 0 && !d.hidden)
    },
    datiUscite(state) {
      return state.dati.filter(d => d.importo < 0 && !d.hidden)
    },
    datiVisibili(state) {
      return state.dati.filter(d => !d.hidden)
    },
    groupDatiBy(state) {
      return (kind, groupBy, dateStart, dateEnd, categoriaIndex) => {
        let groupWeekly = (periodFiltered) => {
          return periodFiltered.reduce(function (grouped, current) {
            const year = moment(current.data_valuta).year();
            const week = moment(current.data_valuta).week();

            grouped[year] = grouped[year] || {};
            if (grouped[year][week] != undefined) {
              grouped[year][week].importo += current.importo;
            } else {
              grouped[year][week] = JSON.parse(JSON.stringify(current)); // Make copy in order NOT to destroy original data
            }
            return grouped;
          }, {});
        }
        let groupMonthly = (periodFiltered) => {
          return periodFiltered.reduce(function (grouped, current) {
            const month = moment(current.data_valuta).month();
            const year = moment(current.data_valuta).year();

            grouped[year] = grouped[year] || {};
            if (grouped[year][month] != undefined) {
              grouped[year][month].importo += current.importo;
            } else {
              grouped[year][month] = JSON.parse(JSON.stringify(current)); // Make copy in order NOT to destroy original data
            }
            return grouped;
          }, {});
        }

        let dati;
        switch (kind) {
          case "entrate":
            dati = state.dati.filter(d => d.importo > 0 && !d.hidden); // stesso come datiEntrate
            break;
          case "uscite":
            dati = state.dati.filter(d => d.importo < 0 && !d.hidden);  // stesso come datiUscite
            break;
          case "visibili":
            dati = state.dati.filter(d => !d.hidden);  // stesso come datiVisibili
            break;
          case "categoria":
            dati = state.dati.filter(d => !d.hidden && d.categoria == categoriaIndex);  // stesso come datiVisibili
            break;
        }

        let periodFiltered = dati.filter(
          d =>
            d.data_valuta >= dateStart &&
            d.data_valuta <= dateEnd
        );
        switch (groupBy) {
          case "week":
            {
              let rawGrouped = groupWeekly(periodFiltered);
              let allEntries = [];
              Object.keys(rawGrouped).forEach(yearKey =>
                Object.keys(rawGrouped[yearKey]).forEach(weekKey =>
                  allEntries.push(rawGrouped[yearKey][weekKey])
                )
              );
              return allEntries.filter(e => e != undefined);
            }
            break;
          case "month":
            {
              let rawGrouped = groupMonthly(periodFiltered);
              let allEntries = [];
              Object.keys(rawGrouped).forEach(yearKey =>
                Object.keys(rawGrouped[yearKey]).forEach(monthKey =>
                  allEntries.push(rawGrouped[yearKey][monthKey])
                )
              );
              return allEntries.filter(e => e != undefined);
            }
            break;
          case "year":
            {
              let monthlyGrouped = groupMonthly(periodFiltered);
              let allEntries = [];
              // Group yearly
              Object.keys(monthlyGrouped).forEach(yearKey =>
                Object.keys(monthlyGrouped[yearKey]).forEach(monthKey => {
                  if (
                    monthlyGrouped[yearKey] != undefined &&
                    monthlyGrouped[yearKey][monthKey] != undefined
                  ) {
                    let current = monthlyGrouped[yearKey][monthKey];

                    if (allEntries[yearKey] == undefined) {
                      allEntries[yearKey] = current;
                    } else {
                      allEntries[yearKey].importo += current.importo;
                    }
                  }
                })
              );
              return allEntries.filter(e => e != undefined);
            }
            break;
        }
      }
    },
    liquidita(state) {
      let liquidita = Number(state.saldo) + Number(state.contanti)
      return parseFloat(Math.round(liquidita * 100) / 100).toFixed(2)
    },
    situazioneFinanziaria(state) {
      let liquidita = Number(state.saldo) + Number(state.contanti)
      let finanziaria = liquidita - Number(state.debiti) + Number(state.crediti)
      return parseFloat(Math.round(finanziaria * 100) / 100).toFixed(2)
    },
    salute(state) {
      let liquidita = Number(state.saldo) + Number(state.contanti)
      let situazioneFinanziaria = liquidita - Number(state.debiti) + Number(state.crediti)
      return situazioneFinanziaria + Number(state.bonus)
    },
    altreSpeseCategoriaIndex(state) {
      return getAltreSpeseCategoriaIndex()
    },
    contantiCategoriaIndex(state) {
      return state.categorie
        .map(cat => cat.name)
        .indexOf("Contanti");
    },
    // Add the `getField` getter to the
    // `getters` of your Vuex store instance.
    getField
  },
  mutations: {
    acceptedCookie(state) {
      state.cookieAccepted = true;
    },
    loadDati(state, loadObj) {

      if (loadObj.isSaldoPolicyToBeSummed) {
        state.saldo = state.saldo + Number(loadObj.saldo)
      } else {
        // Leggo nuovo saldo se più recente
        let computeMaxDataValuta = function (dati) {
          return dati.reduce((maxDataValuta, dato) => {
            if (maxDataValuta == null || moment(dato.data_valuta).isAfter(maxDataValuta)) {
              return dato.data_valuta
            }
            return maxDataValuta
          }, null)
        }

        let maxDataValutaCurrent = computeMaxDataValuta(state.dati)
        let maxDataValutaNew = computeMaxDataValuta(loadObj.dati)

        if ((maxDataValutaNew != null && maxDataValutaCurrent != null && moment(maxDataValutaNew).isSameOrAfter(maxDataValutaCurrent)) || maxDataValutaCurrent == null) {
          state.saldo = Number(loadObj.saldo)
        }
      }

      // Contabilizzo record vecchi
      state.dati.forEach(dato => {
        dato.recent = false
        let contabilizzato = loadObj.dati.find(newDato =>
          new Date(dato.data_valuta).getTime() == new Date(newDato.data_valuta).getTime() && dato.importo == newDato.importo && dato.data_contabile == null
        )
        if (contabilizzato != undefined) {
          dato.data_contabile = contabilizzato.data_contabile
          dato.descrizione = contabilizzato.descrizione
          dato.recent = true
          console.log("Now Contabilizzato", dato)
        }
      })

      let newDatiRemovingOverlapping = loadObj.dati.filter(newDato => {
        let fresh = state.dati.every(dato =>
          !(
            new Date(dato.data_valuta).getTime() == new Date(newDato.data_valuta).getTime() &&
            (
              (dato.data_contabile == null && newDato.data_contabile == null) ||
              (dato.data_contabile != null && newDato.data_contabile != null && new Date(dato.data_contabile).getTime() == new Date(newDato.data_contabile).getTime())
            ) &&
            dato.descrizione == newDato.descrizione &&
            dato.importo == newDato.importo
          )
        )
        let outdatedContabilizzato = state.dati.some(dato =>
          dato.data_contabile != null && newDato.data_contabile == null &&
          new Date(dato.data_valuta).getTime() == new Date(newDato.data_valuta).getTime() &&
          dato.importo == newDato.importo)

        if (!fresh || outdatedContabilizzato) {
          console.log("Overlapping:", !fresh, "old data already contabilizzato", outdatedContabilizzato, "subject:", newDato)
        }
        return fresh && !outdatedContabilizzato
      })

      let applyPredefinedCategorization = function (dato) {
        for (let index = 0; index < state.categorie.length; index++) {
          let categoria = state.categorie[index]
          if (categoria.words.some(word =>
            dato.descrizione != null &&
            (
              dato.descrizione.toLowerCase().includes(word.toLocaleLowerCase() + " ") ||
              dato.descrizione.toLowerCase().includes(" " + word.toLocaleLowerCase())
            ))) {
            return index
          }
        }
        return getAltreSpeseCategoriaIndex()
      }

      let lastIndex = state.dati.length
      state.dati = state.dati.concat(newDatiRemovingOverlapping.map(
        (entry, index) => {
          return {
            id: index + lastIndex,
            ...entry,
            categoria: applyPredefinedCategorization(entry),
            recent: true,
            hidden: false
          }
        })
      )
    },
    loadSingleDato(state, dato) {
      let newEntry = {
        id: state.dati.length,
        data_contabile: dato.data_contabile,
        data_valuta: dato.data_valuta,
        importo: dato.importo,
        descrizione: dato.descrizione,
        categoria: dato.categoriaIndex,
        recent: true,
        hidden: false
      }
      state.dati = state.dati.concat(newEntry)
      dato.returnObj = newEntry.id
    },
    loadExampleDati(state, loadObj) {
      function randomDate(start, end) {
        return new Date(
          start.getTime() + Math.random() * (end.getTime() - start.getTime())
        );
      }

      let randomData = [...Array(500).keys()].map(i => {
        let data_valuta = randomDate(
          moment(new Date())
            .subtract(4, "years")
            .toDate(),
          new Date()
        );
        if (i >= 480) {
          data_valuta = randomDate(
            moment(new Date())
              .startOf('month')
              .toDate(),
            moment(new Date())
              .endOf('month')
              .toDate()
          );
        }
        return {
          id: i,
          data_contabile: moment(data_valuta)
            .add(2, "days")
            .toDate(),
          data_valuta: data_valuta,
          importo:
            (Math.round(1000 * Math.random() * 100) / 100) *
            (Math.random() > 0.5 ? 1 : -1),
          descrizione: "Random expense " + i,
          categoria: Math.round(
            Math.random() * (state.categorie.length - 1),
          ),
          recent: true,
          hidden: false
        };
      })

      state.dati = randomData
      state.saldo = randomData.reduce((prev, cur) => prev + cur.importo, 0)

      loadObj.dati = randomData; // Return 
    },
    hideDato(state, id) {
      state.dati.filter(dato => dato.id == id)[0].hidden = true
    },
    showDato(state, id) {
      state.dati.filter(dato => dato.id == id)[0].hidden = false
    },
    changeCategoria(state, newCatObj) {
      console.log(state.dati.filter(dato => dato.id == newCatObj.indexDati))
      state.dati.filter(dato => dato.id == newCatObj.indexDati)[0].categoria = newCatObj.categoriaIndex
    },
    applyContainsRuleMutation(state, payload) {
      let affectedDati = []
      state.dati.forEach(dato => {
        if (payload.ids.includes(dato.id) && dato.descrizione != undefined && dato.descrizione.toLowerCase().includes(payload.word.trim().toLowerCase())) {
          console.log("Matched '", payload.word.trim(), "' -->", dato.descrizione)
          dato.categoria = payload.index
          affectedDati.push(dato.id)
        }
      })

      payload.affectedDati = affectedDati // Alternative way to return data breaking vuex flow :)
    },
    addContanti(state, contanti) {
      state.contanti = Number(contanti) > 0 ? Number(contanti) : 0;
    },
    setDebitiAndCrediti(state, debitiCreditiObj) {
      state.debiti = Number(debitiCreditiObj.debiti)
      state.crediti = Number(debitiCreditiObj.crediti)
    },
    setBonus(state, bonus) {
      state.bonus = bonus;
    },
    updateMonthDayStart(state, newMonthDayStart) {
      state.monthDayStart = newMonthDayStart
    },
    saveState(state, returnObj) {
      let persistableState = computeEncryptedPersistableState(state)
      let promises = Object.keys(persistableState).map(field => {
        return persistedStore.setItem(field, persistableState[field])
      })
      returnObj.value = Promise.all(promises)
    },
    loadState(state, returnObj) {
      let castObj = function (dati) {
        if (dati == null) return null;
        return dati.map(dato => {
          if (dato.data_contabile != undefined && dato.data_contabile != null) {
            dato.data_contabile = new Date(dato.data_contabile)
          }
          if (dato.data_valuta != undefined && dato.data_valuta != null) {
            dato.data_valuta = new Date(dato.data_valuta)
          }
          return dato;
        })
      }
      let promises = persistedFields.map(field => new Promise((resolve, reject) => {
        let decryptFun = (value) => value
        if (encryptedFields.includes(field)) {
          decryptFun = (value) => decrypt(state.encryptionKey, value)
        }

        let processFieldValue = (value) => {
          let processedValue;
          switch (field) {
            case "dati": processedValue = castObj(decryptFun(value));
              break;
            default:
              processedValue = decryptFun(value)
          }
          return processedValue
        }

        if (returnObj.cloudState != null) {
          let value = returnObj.cloudState[field]
          let processedValue = processFieldValue(value)
          resolve({ field: field, value: processedValue })
        } else {
          persistedStore.getItem(field)
            .then(value => {
              let processedValue = processFieldValue(value)
              resolve({ field: field, value: processedValue })
            })
            .catch(err => reject(err))
        }
      }))

      returnObj.value = Promise.all(promises).then(elements => {
        elements.forEach(promise => {
          let field = promise.field
          let value = promise.value
          if (value != null) {
            state[field] = value
          }
        });

        state.isStateFullyLoaded = true
      }).catch(function (err) {
        console.log(err);
      })
    },
    loadStateLight(state, returnObj) {
      let promises = lightLoadedFields.map(field => new Promise((resolve, reject) => {
        persistedStore.getItem(field)
          .then(value => {
            resolve({ field: field, value: value })
          })
          .catch(err => reject(err))
      }))

      returnObj.value = Promise.all(promises).then(elements =>
        elements.forEach(promise => {
          let field = promise.field
          let value = promise.value
          if (value != null) {
            state[field] = value
          }
        })
      ).catch(function (err) {
        console.log(err);
      })
    },
    resetState(state, returnObj) {
      Object.keys(initialState).forEach(key => state[key] = initialState[key])
      returnObj.value = persistedStore.clear()
    },
    setEncryptKey(state, encryptionKey) {
      state.encrypted = encryptionKey != null
      state.encryptionKey = encryptionKey;
    },
    checkEncryptionKey(state, returnObj) {
      try {
        returnObj.value = persistedStore.getItem(encryptedFields[0])
          .then(value => {
            return decrypt(returnObj.encryptionKey, value) != undefined
          })
          .catch(err => {
            return false;
          })
      } catch (err) {
        returnObj.value = Promise.resolve(false);
      }
    },
    setPassword(state, encryptionKey) {
      state.encryptionKey = encryptionKey;
    },
    turnOnAskPasswordProtection(state) {
      if (state.askPasswordIdOfSetTimeout != null) {
        clearTimeout(state.askPasswordIdOfSetTimeout);
      }
      state.askPasswordIdOfSetTimeout = setTimeout(() => {
        if (state.encrypted) {
          state.encryptionKey = null;
          router.push("/app/protected");
        }
      }, 30 * 60 * 1000);
    },
    turnOffAskPasswordProtection(state) {
      clearTimeout(state.askPasswordIdOfSetTimeout);
      state.askPasswordIdOfSetTimeout = null
    },
    switchToMovimentiView(state) {
      state.tabView = "tabella"
    },
    switchToGraficiView(state) {
      state.tabView = "grafici"
    },
    setSynchInCloud(state, newState) {
      state.synchInCloud = newState
    },
    startSynchingCloud(state) {
      state.cloudState = "progress"
    },
    doneSynchingCloud(state, fileId) {
      state.cloudFileId = fileId
      state.cloudState = "sync"
    },
    errorSynchingCloud(state) {
      state.cloudState = "error"
    },
    setCloudFileId(state, fileId) {
      state.cloudFileId = fileId
    },
    setSynchedCloudState(state){
      state.cloudState = "sync"
    }
  },
  actions: {
    acceptedCookie(context) {
      context.commit("acceptedCookie")
      context.dispatch("saveState")
    },
    applyContainsRule(context, payload) {
      context.commit("applyContainsRuleMutation", payload)
      let returnData = Promise.resolve(payload.affectedDati)
      context.dispatch("saveState")
      return returnData;
    },
    changeCategoria(context, newCatObj) {
      context.commit("changeCategoria", newCatObj)
      context.dispatch("saveState")
    },
    hideDato(context, id) {
      context.commit("hideDato", id)
      context.dispatch("saveState")
    },
    showDato(context, id) {
      context.commit("showDato", id)
      context.dispatch("saveState")
    },
    loadSingleDato(context, dato) {
      context.commit("loadSingleDato", dato)
      let returnData = Promise.resolve(dato.returnObj)
      context.dispatch("saveState")
      return returnData;
    },
    addContanti(context, contanti) {
      context.commit("addContanti", contanti)
      context.dispatch("saveState")
    },
    setDebitiAndCrediti(context, debitiCreditiObj) {
      context.commit("setDebitiAndCrediti", debitiCreditiObj)
      context.dispatch("saveState")
    },
    setBonus(context, bonus) {
      context.commit("setBonus", bonus)
      context.dispatch("saveState")
    },
    loadDati(context, loadObj) {
      context.commit("loadDati", loadObj)
      context.dispatch("saveState")
    },
    loadExampleDati(context, loadObj) {
      context.commit("loadExampleDati", loadObj)
      context.dispatch("saveState")
    },
    updateMonthDayStart(context, newMonthDayStart) {
      context.commit("updateMonthDayStart", newMonthDayStart)
      context.dispatch("saveState")
    },
    saveState(context) {
      let returnObj = { value: null }
      context.commit("saveState", returnObj)
      if (context.state.synchInCloud) {
        context.dispatch('saveInCloud')
      }
      return returnObj.value
    },
    loadState(context, forceNoCloud) {
      return new Promise((resolve, reject) => {

        let doLoadState = (cloudState) => {
          let returnObj = { value: null, cloudState: cloudState }
          context.commit("loadState", returnObj)
          return returnObj.value
        }

        Vue.prototype.$gapi.getGapiClient().then(gapi => {
          gapi.client.load("drive", "v3").then(() => {
            let isLoggedIn = forceNoCloud ? false : gapi.auth2.getAuthInstance().isSignedIn.get();
            console.log("Cloud activated?", isLoggedIn);
            context.commit("setSynchInCloud", isLoggedIn)

            if (isLoggedIn) {
              Vue.prototype.$gapi.getGapiClient().then(gapi => {
                gapi.client.drive.files
                  .list({
                    spaces: "appDataFolder",
                    fields: "nextPageToken, files(id, name)",
                    pageSize: 1
                  })
                  .then(
                    res => {
                      if (res.result.files.length > 0) {
                        let fileId = res.result.files[0].id
                        context.commit('setCloudFileId', fileId)
                        gapi.client.drive.files.get({
                          fileId: fileId,
                          alt: 'media'
                        }).then(cloudState =>
                          doLoadState(JSON.parse(cloudState.body)).then(() => {
                            let returnObj = {}
                            context.commit('setSynchedCloudState')
                            context.commit("saveState", returnObj)
                            returnObj.value.then(() => resolve())
                          }))
                      } else {
                        doLoadState(null).then(() => resolve())
                      }
                    }
                  )
                  .catch(err => console.error(err));
              })
            } else {
              doLoadState(null).then(() => resolve())
            }
          })
        });
      })
    },
    loadStateLight(context) {
      let returnObj = { value: null }
      context.commit("loadStateLight", returnObj)
      return returnObj.value
    },
    resetState({ commit, state }) {
      if (state.synchInCloud && state.cloudFileId != null) {
        let fileId = state.cloudFileId
        Vue.prototype.$gapi.getGapiClient().then(gapi => {
          gapi.client.drive.files.delete({
            fileId: fileId
          }).then(() => {
            console.log("Cloud deleted")
          })
        })
      }

      let returnObj = { value: null }
      commit("resetState", returnObj)
      return returnObj.value
    },
    encryptState(context, encryptionKey) {
      context.commit("setEncryptKey", encryptionKey)
      persistedStore.clear()
      context.dispatch("saveState")
    },
    unencryptState(context) {
      context.commit("setEncryptKey", null)
      persistedStore.clear()
      context.dispatch("saveState")
    },
    checkEncryptionKey(context, encryptionKey) {
      let returnObj = { encryptionKey: encryptionKey, value: null }
      context.commit("checkEncryptionKey", returnObj)
      return returnObj.value
    },
    setPassword(context, encryptionKey) {
      context.commit("setPassword", encryptionKey)
    },
    turnOnAskPasswordProtection(context) {
      context.commit("turnOnAskPasswordProtection")
    },
    turnOffAskPasswordProtection(context) {
      context.commit("turnOffAskPasswordProtection")
    },
    changeUseOfCloud(context) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$gapi.getGapiClient().then(gapi => {
          let client = gapi.auth2.getAuthInstance()
          let operation = context.state.synchInCloud ? client.signOut() : client.signIn()
          operation.then(() => {
            let newSynchInCloud = !context.state.synchInCloud
            console.log("Cloud:", newSynchInCloud)
            context.commit('setSynchInCloud', newSynchInCloud)
            context.dispatch('loadState', !newSynchInCloud)
            resolve(context.state.synchInCloud)
          }).catch(err => {
            console.log("Error Cloud:", err);
            reject(err)
          })
        })
      })
    },
    saveInCloud({ commit, state }) {
      return new Promise((resolve, rejected) => Vue.prototype.$gapi.getGapiClient().then(gapi => {
        commit("startSynchingCloud")

        const name = 'state.json'
        const data = JSON.stringify(computeEncryptedPersistableState(state))

        const fileIdForURL = state.cloudFileId == null ? "" : '/' + state.cloudFileId
        const method = state.cloudFileId == null ? "POST" : "PATCH"

        const boundary = '-------314159265358979323846';
        const delimiter = "\r\n--" + boundary + "\r\n";
        const close_delim = "\r\n--" + boundary + "--";

        const contentType = 'application/json';

        let metadata = {
          'name': name,
          'mimeType': contentType
        };
        if (state.cloudFileId == null)
          metadata['parents'] = ['appDataFolder']

        let multipartRequestBody =
          delimiter +
          'Content-Type: application/json\r\n\r\n' +
          JSON.stringify(metadata) +
          delimiter +
          'Content-Type: ' + contentType + '\r\n\r\n' +
          data +
          close_delim;

        gapi.client.request({
          'path': '/upload/drive/v3/files' + fileIdForURL,
          'method': method,
          'params': { 'uploadType': 'multipart' },
          'headers': {
            'Content-Type': 'multipart/related; boundary="' + boundary + '"'
          },
          'body': multipartRequestBody
        }).then(file => {
          commit("doneSynchingCloud", file.result.id)
          console.log("Cloud synched")
          resolve()
        }).catch(err => {
          console.error(err)
          commit("errorSynchingCloud")
          rejected(err)
        })
      }))
    }
  }
})
