<template>
  <div id="app" class="wrapper">
    <div id="nav">
      <b-navbar fixed-top shadow>
        <template slot="brand">
          <b-navbar-item tag="router-link" to="/">
            <img id="menu-home-img" src="./assets/brand.svg" alt="ComeSto.biz" />
          </b-navbar-item>
        </template>

        <template slot="start">
          <b-navbar-item tag="router-link" to="/">Home</b-navbar-item>
          <b-navbar-item tag="router-link" to="/faq">Domande frequenti</b-navbar-item>
          <b-navbar-item tag="router-link" to="/privacy">Privacy</b-navbar-item>
          <b-navbar-item tag="router-link" to="/app">Applicazione</b-navbar-item>
        </template>

        <!-- <template slot="end">
          <b-navbar-item tag="div">
            <div class="buttons">
              <a class="button is-primary">
                <strong>Sign up</strong>
              </a>
              <a class="button is-light">Log in</a>
            </div>
          </b-navbar-item>
        </template>-->
      </b-navbar>
    </div>
    <div class="main-content">
      <router-view />
    </div>
    <footer class="footer">
      <nav class="columns is-multiline is-gapless">
        <div class="column is-4" style="text-align: left">
          <p>&nbsp;&copy; 2019 - ComeSto.biz</p>
        </div>

        <div class="column is-4">
          <social-sharing
            url="http://www.comesto.biz"
            title="Come spendi i tuoi soldi?"
            description="Il servizio della tua banca non offre grafici e categorizzazione dei movimenti? ComeSto.biz è quello di cui hai bisogno. Importa Excel dalla tua banca online e rispondi alla domanda economicamente 'ComeSto'? Categorizza i movimenti e consulta il report con i grafici."
            quote="Il servizio della tua banca non offre grafici e categorizzazione dei movimenti? ComeSto.biz è quello di cui hai bisogno."
            inline-template
          >
            <div>
              Condividi:
              <b-tooltip label="E-Mail">
                <network network="email">
                  <i class="mdi mdi-email-box"></i>
                </network>
              </b-tooltip>
              <b-tooltip label="Facebook">
                <network network="facebook">
                  <i class="mdi mdi-facebook-box"></i>
                </network>
              </b-tooltip>
              <b-tooltip label="Linkedin">
                <network network="linkedin">
                  <i class="mdi mdi-linkedin"></i>
                </network>
              </b-tooltip>
              <b-tooltip label="Telegram">
                <network network="telegram">
                  <i class="mdi mdi-telegram"></i>
                </network>
              </b-tooltip>
              <b-tooltip label="Twitter">
                <network network="twitter">
                  <i class="mdi mdi-twitter"></i>
                </network>
              </b-tooltip>
              <b-tooltip label="Whatsapp">
                <network network="whatsapp">
                  <i class="mdi mdi-whatsapp"></i>
                </network>
              </b-tooltip>
            </div>
          </social-sharing>
        </div>

        <div class="column is-4" style="text-align: right">
          <p>
            Email:
            <a
              class="mail"
              href="mailto:assistenza@comesto.biz"
              title="Contattaci"
            >assistenza@comesto.biz</a>
          </p>
        </div>
      </nav>
    </footer>
  </div>
</template>

<script>
import "blockadblock";

export default {
  mounted() {
    this.$store.dispatch("loadStateLight").then(() => {
      document.dispatchEvent(new Event("x-app-rendered"));

      const afterPreRendering = () => {
        if (!this.$store.state.cookieAccepted) {
          this.$buefy.snackbar.open({
            indefinite: true,
            message:
              "Questo sito usa i cookie per migliorare l'esperienza di navigazione. Usando questo sito acconsenti a tutti i cookie in accordo alla Cookie Policy.",
            type: "is-warning",
            position: "is-bottom-right",
            actionText: "Va Bene",
            queue: true,
            onAction: () => {
              this.$store.dispatch("acceptedCookie");
            }
          });
        }
      };
      setTimeout(afterPreRendering, 3000); // Wait sometime due to pre-rendering build!
    });

    let adBlockDetected = () => {
      console.log("AdBlock detected");
      this.$buefy.toast.open({
        duration: 10000,
        message:
          "<p><strong><center>E' stato rilevato un ads blocker.</center></strong><br/> Non abbiamo pubblicità invadente, disattivalo se non lo hai già fatto. Grazie!</p>",
        type: "is-warning",
        position: "is-bottom",
        queue: false
      });
    };
    // adblock check
    if (typeof window.blockAdBlock === "undefined") {
      adBlockDetected();
    } else {
      window.blockAdBlock.onDetected(adBlockDetected);
      window.blockAdBlock.onNotDetected(() => {
        console.log("AdBlock NOT detected");
      });
    }
  }
};
</script>

<style scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 45px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}

.mail {
  color: white;
  padding-right: 4em;
}

#menu-home-img {
  max-height: 3rem;
}
</style>
