<template>
  <b-modal :active.sync="show" trap-focus aria-role="dialog" aria-modal has-modal-card>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Donazione</p>
      </header>
      <section class="modal-card-body section">
        <h2 class="subtitle is-muted">Di seguito i metodi di pagamento disponibili:</h2>
        <div class="columns" style="align-items: center; flex-direction: column">
          <div class="column">
            <section class="card" style="padding: 10px">
              <div class="card-head">
                <h2 class="subtitle is-5 is-muted">Bonifico bancario</h2>
              </div>
              <br />
              <div class="card-body">
                <table class="table is-narrow">
                  <tbody>
                    <tr>
                      <td>
                        <b>Intestatario:</b>
                      </td>
                      <td>
                        <div class="media">
                          <div class="media-content">Esposito Andrea</div>
                          <a @click="copyToClipboard('Esposito Andrea')" title="Copia">
                            <b-icon class="media-right" icon="content-copy"></b-icon>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>IBAN:</b>
                      </td>
                      <td>
                        <div class="media">
                          <div class="media-content">IT24D 03058 01604 100571955198</div>
                          <a @click="copyToClipboard('IT24D0305801604100571955198')" title="Copia">
                            <b-icon class="media-right" icon="content-copy"></b-icon>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Causale:</b>
                      </td>
                      <td>
                        <div class="media">
                          <div class="media-content">Donazione applicazione comesto</div>
                          <a
                            @click="copyToClipboard('Donazione applicazione comesto')"
                            title="Copia"
                          >
                            <b-icon class="media-right" icon="content-copy"></b-icon>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </div>
          <div class="column">Oppure</div>
          <div class="column">
            <section class="card" style="padding: 2em">
              <div class="card-head">
                <figure class="image">
                  <img src="../assets/PayPal.svg" alt="PayPal" />
                </figure>
              </div>
              <div class="card-body" style="margin-top: 1em">
                <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                  <input type="hidden" name="cmd" value="_donations" />
                  <input type="hidden" name="business" value="BAHCYE4LSKJMU" />
                  <input type="hidden" name="item_name" value="Applicazione %22Come st%C3%B2%22" />
                  <input type="hidden" name="currency_code" value="EUR" />
                  <input
                    type="image"
                    src="https://www.paypalobjects.com/it_IT/IT/i/btn/btn_donateCC_LG.gif"
                    border="0"
                    name="submit"
                    title="PayPal - The safer, easier way to pay online!"
                    alt="Fai una donazione con il pulsante PayPal"
                  />
                  <img
                    alt
                    border="0"
                    src="https://www.paypal.com/it_IT/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </div>
            </section>
          </div>
        </div>
        <h2 class="subtitle is-muted">Grazie del tuo supporto</h2>
      </section>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "donazione",
  props: ["modalOpen"],
  computed: {
    show: {
      get() {
        return this.modalOpen;
      },
      set(value) {
        this.$emit("update:modalOpen", value);
      }
    }
  },
  methods: {
    copyToClipboard(value) {
      this.$clipboard(value);
      this.$buefy.toast.open({
        message: `Copiato`,
        position: "is-bottom",
        type: "is-success"
      });
    }
  }
};
</script>